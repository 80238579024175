<template>
  <div id="Data-center" class="main-wrap">
    <!-- 待办事项 -->
    <div class="screen-data">
      <h3 class="head-title">待办事项</h3>
      <div class="enter-flex">
        <div class="enter-item" v-for="(item,index) in headList" :key="index" @click="toUrl(index)">
          <i>进入</i>
          <div class="inner">
            <img class="icon" :src="headIconList[index].icon" alt="">
            <div >
              <p class="num">{{item.value}}</p>
              <span class="font">{{item.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="screen-data l-r-layout trade-box">
      <div class="trade-inner">
        <div class="head-flex">
          <h3 class="head-title">交易概况</h3>
          <a-select class="data" v-model="timeIndex" placeholder="请选择">
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option :value="1">自然日</a-select-option>
            <a-select-option :value="2">自然周</a-select-option>
            <a-select-option :value="5">月份</a-select-option>
            <a-select-option :value="4">年份</a-select-option>
          </a-select>
          
          <!-- 自然日选择器 -->
          <a-date-picker 
            v-show="timeIndex == 1"
            v-model="MonthData"
            class="data" 
            placeholder="请选择日期" 
            @change="onChangeOnlyData" 
            :defaultValue="$moment(getCurrentData(), 'YYYY-MM-DD')" 
            :allowClear="false"
            valueFormat="YYYY-MM-DD">
            <a-icon slot="suffixIcon" type="carry-out" :style="{color: '#3681F0'}" />
          </a-date-picker>

          <!-- 自然周选择器 -->
          <a-range-picker 
            class="data" 
            v-show="timeIndex == 2" 
            :disabled-date="disabledDate" 
            :allowClear="false"
            v-model="priceRangeDate" 
            valueFormat="YYYY-MM-DD"
            @calendarChange="calendarPriceRangeChange" 
            @change="changePriceRangeDate">
            <a-icon slot="suffixIcon" type="carry-out" :style="{color: '#3681F0'}" />
          </a-range-picker>

          <!-- 月份/年份选择器 -->
          <a-date-picker
            v-show="timeIndex == 4 || timeIndex == 5"
            :open="showMonthPicker"
            :defaultValue="$moment(getCurrentData(), 'YYYY-MM')" 
            v-model="MonthData"
            :allowClear="false"
            @panelChange="handlePanelChange"
            @openChange="handleOpenChange"
            placeholder="请选择月份" 
            :format="timeIndex == 4 ? 'YYYY' : 'YYYY-MM'" 
            :valueFormat="timeIndex == 4 ? 'YYYY' : 'YYYY-MM'"
            class="data" 
            :mode="timeIndex == 4 ? 'year' : 'month'">
            <a-icon slot="suffixIcon" type="carry-out" :style="{color: '#3681F0'}" />
          </a-date-picker>
        </div>
        <div class="white-inner">
          <div class="date-wrap" >
            <div class="date-list">
              <div class="date-item" v-for="(item,index) in tradeVisitor" :key="index">
                <p class="name">
                  {{item.name}}
                  <a-tooltip placement="bottom" :title="item.tips">
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </p>
                <p class="num">{{item.value}}</p>
                <p class="balance">(待{{fun_type()}}
                  <template v-if="item.rate == 1 && item.trade == 0">持平)</template>
                  <template v-else>
                    <img :src="item.rate == 2 ? downIocn : upIocn" alt="">{{item.trade}}人)
                  </template>
                </p>
              </div>
              <div class="line line-01"></div>
            </div>
            <div class="date-list">
              <div class="date-item" v-for="(item,index) in tradeOrder" :key="index">
                <p class="name">
                  {{item.name}}
                  <a-tooltip placement="bottom" :title="item.tips">
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </p>
                <p class="num">{{item.value}}</p>
                <p class="balance">(待{{fun_type()}}
                  <template v-if="item.rate == 1 && item.trade == 0">持平)</template>
                  <template v-else>
                    <img :src="item.rate == 2 ? downIocn : upIocn" alt="">{{item.trade}}{{index==0?'人':'元'}})
                  </template>
                </p>
              </div>
              <div class="line line-02"></div>
            </div>
            <div class="date-list">
              <div class="date-item" v-for="(item,index) in tradePay" :key="index">
                <p class="name">
                  {{item.name}}
                  <a-tooltip placement="bottom" :title="item.tips">
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </p>
                <p class="num">{{Math.floor(item.value)}}</p>
                <p class="balance" v-if="index!=3">
                  (待{{fun_type()}}
                  <template v-if="item.rate == 1 && item.trade == 0">持平)</template>
                  <template v-else>
                    <img :src="item.rate == 2 ? downIocn : upIocn" alt="">{{item.trade}}{{index==0?'人':'元'}})
                  </template>
                </p>
                <p class="balance detail" @click="toUrl(5)" v-else>查看详情<a-icon type="double-right" :style="{fontSize: '11px', color: '#3681F0'}" class="icon"/></p>
              </div>
              <!-- <div class="date-item">
                <p class="name">待支付订单</p>
                <p class="num">6107</p>
                <p class="balance detail">查看详情<a-icon type="double-right" :style="{fontSize: '11px', color: '#3681F0'}" class="icon"/></p>
              </div> -->
              <div class="line line-03"></div>
            </div>
          </div>
          <div class="echarts-wrap">
            <div class="funnel-div">
              <img class="icon icon-top" src="@/assets/image/dataCenter/funnel-top.png" alt="">
              <!-- 漏斗图 -->
              <div class="myChart-funnel" ref="myChart1">1</div>
              <img class="icon icon-bottom" src="@/assets/image/dataCenter/funnel-bottom.png" alt="">
            </div>
            <div class="conversion-rate">
              <div class="rate-item rate-01">
                <p class="name">
                  访客下单转化率
                  <a-tooltip placement="bottom" title="访客量/下单人数*100%">
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </p>
                <p class="num">{{Conversion[0].name}}</p>
              </div>
              <div class="rate-item rate-02">
                <p class="name">
                  下单支付转化率
                  <a-tooltip placement="bottom" title="下单人数/支付人数*100%">
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </p>
                <p class="num">{{Conversion[1].name}}</p>
              </div>
              <div class="rate-item rate-03">
                <p class="name">
                  访客支付转化率
                  <a-tooltip placement="bottom" title="访客量/支付人数*100%">
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </p>
                <p class="num">{{Conversion[2].name}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="system-inner">
        <div class="head-flex">
          <h3 class="head-title">系统更新 </h3>
          <span class="time" v-if="false">（发版时间：<i>2024-12-09</i>）</span>
        </div>
        <div class="content-list" v-if="updateDetails">
          <div class="white-inner" v-html="updateDetails.dictValue"></div>
          <a class="more-btn" :href="updateDetails.content" target="_blank">
            功能更新详情<a-icon type="double-right" :style="{fontSize: '12px', color: '#3681F0'}" class="icon"/>
          </a>
        </div>
        <div class="white-inner empty" v-else>
          <a-empty description="暂无更新内容" />
        </div>
      </div>
    </div>
    <div class="screen-data l-r-layout payment-box">
      <div class="left-inner">
        <div class="head-flex">
          <h3 class="head-title">回款明细</h3>
          <a-select class="data" placeholder="请选择" @change="onChangePaymentTime()" v-model="paymentIndex" >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option :value="1">本日</a-select-option>
            <a-select-option :value="2">本周</a-select-option>
            <a-select-option :value="3">本月</a-select-option>
            <a-select-option :value="6">本年</a-select-option>
          </a-select>
        </div>
        <div class="white-inner">
          <div class="myChart-cross" ref="myChart2">1</div>
        </div>
      </div>
      <div class="right-inner">
        <div class="head-flex">
          <h3 class="head-title">商品销售TOP</h3>
        </div>
        <div class="white-inner">
          <div class="xls-th-tr">
            <div class="xls-th-td td-01">序号</div>
            <div class="xls-th-td td-02">课程名称</div>
            <div class="xls-th-td td-03">购课人数</div>
            <div class="xls-th-td td-04">销售金额</div>
          </div>
          <div class="seamless-scroll">
            <vue-seamless-scroll  :data="LampList" :class-option="LampOption">
              <div class="xls-th-tr orderline" v-for="(item,index) in LampList" :key="index">
                <div class="xls-th-td td-01">{{index + 1}}</div>
                <div class="xls-th-td td-02">{{item.name}}</div>
                <div class="xls-th-td td-03">{{item.value}}</div>
                <div class="xls-th-td td-04">{{item.value2}}</div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { fun_formatData } from '@/unit/fun.js'
import { getData } from "@/unit/common";
import vueSeamlessScroll from 'vue-seamless-scroll';  // 引入跑马灯组件
export default {
  // 可用组件的哈希表
  components: {vueSeamlessScroll},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      headList:[],
      headIconList:[
        { icon: require('@/assets/image/dataCenter/matter-icon1.png') },
        { icon: require('@/assets/image/dataCenter/matter-icon2.png') },
        { icon: require('@/assets/image/dataCenter/matter-icon3.png') },
        { icon: require('@/assets/image/dataCenter/matter-icon4.png') },
        { icon: require('@/assets/image/dataCenter/matter-icon5.png') },
        { icon: require('@/assets/image/dataCenter/matter-icon6.png') },
      ],

      /* 交易概况数据 */
      tradeVisitor:[], // 访客
      tradeOrder:[], // 下单
      tradePay:[], // 支付

      
      /* 跑马灯配置 */
      LampOption: {
        step: 0.2, // 数值越大速度滚动越快
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000
      },
      LampList:[],
      downIocn: require('@/assets/image/dataCenter/icon-bend-down.png'),
      upIocn: require('@/assets/image/dataCenter/icon-bend-up.png'),

      timeIndex: 1,
      showMonthPicker:false,
      MonthData:null,

      // 自然周日期框参数
      priceRangeDate: [],
      selectPriceDate: '',
      offsetDays: 86400000 * 7, //最多选择7天

      paymentIndex: 1, // 回款查询状态
      onlienData:[], // 线上数据
      offlienData:[], // 线下数据

      Conversion:[{name:'0%'},{name:'0%'},{name:'0%'}],

      updateDetails:null,
    }
  },
  // 事件处理器
  methods: {
    // 漏斗图
    myChartFunnel() {
      var myChart = echarts.getInstanceByDom(this.$refs.myChart1);
      if (!myChart) {
        myChart = echarts.init(this.$refs.myChart1);
      }
      var option = {
        tooltip: {
          trigger: 'item',
          // formatter: '{b}人数 : {c}'
          formatter: (params) => {
            let value = 0
            if(params.dataIndex == 0){
              value = this.tradeVisitor[0].value
            } else if(params.dataIndex == 1){
              value = this.tradeOrder[0].value
            } else if(params.dataIndex == 2){
              value = this.tradePay[0].value
            }
            // 自定义提示语
            const str = `${params.name}人数 : ${value}人`;
            return str;
          },
        },
        series: [
          {
            name: '交易概况',
            type: 'funnel',
            left: 0,
            top: 0,
            bottom: 0,
            width: '100%',
            min: 0,
            max: 100,
            minSize: '0%',
            maxSize: '166%',
            sort: 'descending',
            gap: 1,
            label: {
              show: true,
              color:'#fff',
              position: 'inside',
              fontSize: 14,
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: 'solid'
              }
            },
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1,
            },
            emphasis: {
              label: {
                fontSize: 15
              }
            },
            data: [
              { value: 60, name: '访客',itemStyle: { color: '#9AC1F0'} },
              { value: 40, name: '下单',itemStyle: { color: '#F6C445'} },
              { value: 20, name: '支付',itemStyle: { color: '#FF8A00'} },
            ]
          }
        ]
      };

      //将配置项设置进去
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    // 折线图
    myChartLine() {
      var myChart = echarts.getInstanceByDom(this.$refs.myChart2);
      if (!myChart) {
        myChart = echarts.init(this.$refs.myChart2);
      }
      var option = {
        color: ['#0EF084', '#4BAFE6'],
        legend: {
          // data: ['线上', '线下']
          // orient: 'vertical',
          right: 30,
          icon: 'rect',
          itemHeight: 13,//图例图标的高度
          itemWidth: 13,//图例图标的宽度
          // y: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          backgroundColor: '#fff', // 悬浮框背景色
          borderColor: '#F6C445', // 悬浮框边框颜色
          borderWidth: 1, // 悬浮框边框宽度
          textStyle: { // 悬浮框文字样式
            color: '#000',
            fontSize: 12
          },
          formatter: (params) => {
            let str = ''
            if(params.length > 1){
              str = `${params[0].name}<br/>${params[0].marker}${params[0].seriesName}：${params[0].value} 元<br/>${params[1].marker}${params[1].seriesName}：${params[1].value} 元`;
            } else {
              str = `${params[0].name}<br/>${params[0].marker}${params[0].seriesName}：${params[0].value} 元`;
            }
            // 自定义提示语
            return str;
          },

        },
        grid: {
          left: '4%',
          right: '6%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.onlienData.map((item) => this.paymentIndex == 1 ? item.name.substring(10,16) : item.name)
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '线上',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 2,
            },
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#0EF083" },
                { offset: 0.1, color: "rgba(14,239,131,0.3)" },
                { offset: 1, color: "rgba(196,196,196,0.1)" },
              ]),
            },
            emphasis: {
              focus: 'series'
            },
            data: this.onlienData.map((item) => item.value)

          },
          {
            name: '线下',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 2
            },
            showSymbol: false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#4BAFE6" },
                { offset: 0.3, color: "rgba(186,222,248,0.6)" },
                { offset: 1, color: "rgba(255,255,255,0.1)" },
              ]),
            },
            emphasis: {
              focus: 'series'
            },
            data: this.offlienData.map((item) => item.value)
          }
        ]
      };

      //将配置项设置进去
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

    // 默认获取今天时间
    getCurrentData(){
      return new Date().toLocaleDateString();
    },

    onChangeOnlyData(Time){
      const YearMonth = Time + " 00:00:00"; // 查询年月数据需要拼接该格式
      const DayLast = Time + " 23:59:59"; 
      this.getList("rpt_transactionProfile", 9, YearMonth, DayLast); // 查询交易概况
      this.getList("rpt_productSalesRanking", 9, YearMonth, DayLast); // 查询销售总额
    },

    // 回款时间查询段查询
    onChangePaymentTime(){
      this.getList("rpt_paymentStatus", this.paymentIndex); // 查询总体数据
    },

    // 月份/年份 日期选择框  打开
    handleOpenChange(status) {
      this.showMonthPicker= status;
    },

    // 月份/年份 日期选择框  关闭
    handlePanelChange(value) {
      let MonthData = this.$moment(value).format(this.timeIndex == 4 ? "YYYY" : "YYYY-MM")
      let timeStr = MonthData
      if(this.timeIndex == 5){
        timeStr = MonthData + '-01 00:00:00'
      }
      this.getList("rpt_transactionProfile", this.timeIndex, timeStr); // 查询交易概况
      this.getList("rpt_productSalesRanking", this.timeIndex, timeStr); // 查询销售总额
      this.MonthData = MonthData
      
      this.showMonthPicker= false;
    },

    //选择完时间 清空限制
    changePriceRangeDate(date) {
      const YearMonth = date[0] + " 00:00:00"; // 查询年月数据需要拼接该格式
      const DayLast = date[1] + " 23:59:59"; 
      this.getList("rpt_transactionProfile", 9, YearMonth, DayLast); // 查询销售总额
      this.getList("rpt_productSalesRanking", 9, YearMonth, DayLast); // 查询销售总额
      this.selectPriceDate = ''
    },
    //选择开始时间/结束时间
    calendarPriceRangeChange(date){
      this.selectPriceDate = date[0]
      
    },
    //根据选择的开始时间/结束时间，动态渲染要禁用的日期
    disabledDate(current){
      if(this.selectPriceDate){
        let selectV = this.$moment(this.selectPriceDate, 'YYYY-MM-DD').valueOf()
        return current > this.$moment(new Date(selectV+this.offsetDays), 'YYYY-MM-DD') ||
          current < this.$moment(new Date(selectV-this.offsetDays), 'YYYY-MM-DD')
      }else{
        return false
      }
    },

    fun_type(){
      let mapObj = { 1:'昨日', 2:'上周', 5:'上月', 4:'上年'}
      return mapObj[this.timeIndex]
    },

    toUrl(index){
      if(index == 0){
        this.$router.push({ path: "/admin/OrderConfig/LineOrder?payMethod=3"}) // 对公转账审核
      } else if(index == 1){
        this.$router.push({ path: "/admin/Examination/ExamSignUp"}) // 考试报名待审核
      } else if(index == 2){
        this.$router.push({ path: "/admin/UserManage/ProblemFeedback"}) // 消息反馈待处理
      } else if(index == 3){
        this.$router.push({ path: "/admin/Mechanism/agencyCertification?type=OperationsHome"}) // 机构端认证待审核
      } else if(index == 4){
        this.$router.push({ path: "/admin/AuditManage/CoursePurchase"}) // 提交证书待审核
      } else if(index == 5){
        this.$router.push({ path: "/admin/OrderConfig/LineOrder"}) // 实体订单代发货
      }
    },

    // 获取数据
    getList(pName, dateType, time1, time2){
      let dataJson = {
        dateType: dateType ? dateType : undefined,
        time1: time1 ? time1 : undefined,
        time2: time2 ? time2 : undefined,
      };
      let params = {
        inString: dateType ? JSON.stringify(dataJson) : undefined,
        pName: pName,
      };
      getData(params).then((res) => {
        if (res.code == 200 && res.success && res.data) {
          if( pName == 'rpt_ManagementHomePage'){
            // 代办事项
            let resultData = fun_formatData(res.data);
            this.headList = resultData.data
          } else if (pName == 'rpt_transactionProfile' ){
            // 交易回款
            let resultArr = res.data.split("$$"); // 按数据code和data进行分割区分
            let resultCode = resultArr[0].split("@@");
            let resultData = resultArr[1].split("@@");

            let tradeList = []
            let tradeBalance = []
            resultData.forEach((element,index) => {
              let str = resultCode + '$$' + element
              let res = fun_formatData(str)
              if(index == 0){
                tradeList = res.data
              } else if (index == 1){
                tradeBalance = res.data
              } else if (index == 2){
                this.Conversion = res.data
              }
            });
            this.tradeVisitor = []
            this.tradeOrder = []
            this.tradePay = []
            let tips = [
              '启动应用的用户（以设备为判断标准，设备去重）',
              '在时间范围内，注册账号的个人用户',
              '在时间范围内，注册账号的机构用户',
              '含取消支付在内的所有订单数（用户去重）',
              '含取消支付在内的所有订单的实付款总额',
              '成功支付的用户数量（不含退款用户）',
              '成功支付的订单实付款总金额（不含退款订单）',
              '回款金额/支付人数',
              '订单状态为待付款的订单数量',
            ]
            for(let i = 0; i < tradeList.length; i++){
              if(i<=2){
                this.tradeVisitor.push({
                  name: tradeList[i].name,
                  value: tradeList[i].value,
                  trade: tradeBalance[i] ? tradeBalance[i].value : undefined,
                  rate: tradeBalance[i] ? tradeBalance[i].value2 : undefined,
                  tips: tips[i]
                })
              } else if(i > 2 && i<=4){
                this.tradeOrder.push({
                  name: tradeList[i].name,
                  value: tradeList[i].value,
                  trade: tradeBalance[i] ? tradeBalance[i].value : undefined,
                  rate: tradeBalance[i] ? tradeBalance[i].value2 : undefined,
                  tips: tips[i]
                })
              } else if(i > 4 && i<=8){
                this.tradePay.push({
                  name: tradeList[i].name,
                  value: tradeList[i].value,
                  trade: tradeBalance[i] ? tradeBalance[i].value : undefined,
                  rate: tradeBalance[i] ? tradeBalance[i].value2 : undefined,
                  tips: tips[i]
                })
              }
            }
          } else if (pName == 'rpt_paymentStatus' ){
            // 回款明细
            let resultArr = res.data.split("$$"); // 按数据code和data进行分割区分
            let resultCode = resultArr[0].split("@@");
            let resultData = resultArr[1].split("@@");
            resultData.forEach((element,index) => {
              let str = resultCode + '$$' + element
              let res = fun_formatData(str)
              if(index == 0){
                this.onlienData = res.data
              } else if (index == 1){
                this.offlienData = res.data
              }
            });
          }  else if (pName == 'rpt_productSalesRanking' ){
            // 销售总额
            let resultData = fun_formatData(res.data);
            this.LampList = resultData.data
          }
        } else {
          if (pName == 'rpt_productSalesRanking' ){
            this.LampList = []
          }
        }
      })
    },

    getUpDateList(){
      this.$ajax({
        url: "/hxclass-management/dict/get",
        method: "get",
        params: {
          c: 'sys_update_log_manage',
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.updateDetails = res.data
        } else {
          this.$message.success(res.message);
        }
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    const date = new Date();
    const Year = date.getFullYear();
    const Month = ((date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1));
    const Day = date.getDate();
    const YearMonth = Year + "-" + Month + "-" + Day +" 00:00:00"; // 查询年月数据需要拼接该格式
    const DayLast = Year + "-" + Month + "-" + Day + " 23:59:59"; 

    this.getList("rpt_ManagementHomePage"); // 查询代办事项
    this.getList("rpt_transactionProfile", 9, YearMonth, DayLast); // 查询交易概况
    this.getList("rpt_paymentStatus", 1); // 查询回款明细
    this.getList("rpt_productSalesRanking", 9, YearMonth, DayLast); // 查询销售总额
   },
  // 生命周期-实例挂载后调用
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.myChartFunnel();
        this.myChartLine();
      }, 200);
    });
    this.getUpDateList()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    timeIndex(value){
      const date = new Date();
      const Year = date.getFullYear();
      const Month = ((date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1));
      const Day = date.getDate();
      if(value == 1){
        // 自然日
        const YearMonth = Year + "-" + Month + "-" + Day +" 00:00:00"; // 查询年月数据需要拼接该格式
        const DayLast = Year + "-" + Month + "-" + Day + " 23:59:59"; 
        this.getList("rpt_transactionProfile", 9, YearMonth, DayLast ); // 查询交易概况
        this.getList("rpt_productSalesRanking", 9, YearMonth, DayLast ); // 查询销售总额
      } else if(value == 2){
        // 自然周
        // 获取今天日期
        const today = new Date();
        // 获取本周周一（周一可能是过去或未来的日期）
        const thisMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1);
        
        // 初始化日期数组，从本周周一开始
        let dates = [];
        for (let i = 0; i <= (today.getDate() - thisMonday.getDate()); i++) {
          // 创建一个新的Date对象来代表每一天
          const date = new Date(thisMonday);
          date.setDate(date.getDate() + i);
          // 将日期添加到数组中
          dates.push(this.$moment(new Date(date)).format("YYYY-MM-DD"));
        }
        // 打印结果
        const firstItem = dates[0]
        const lastItem = dates.pop()
        this.priceRangeDate = [firstItem, lastItem]
        const YearMonth = firstItem +" 00:00:00"; // 查询年月数据需要拼接该格式
        const DayLast = lastItem + " 23:59:59"; 
        this.getList("rpt_transactionProfile", 9, YearMonth, DayLast ); // 查询交易概况
        this.getList("rpt_productSalesRanking", 9, YearMonth, DayLast); // 查询销售总额
      } else if(value == 5){
        // 月份
        let MonthStr = Year + '-' + Month + '-01 00:00:00'
        this.getList("rpt_transactionProfile", 5, MonthStr ); // 查询交易概况
        this.getList("rpt_productSalesRanking", 5, MonthStr); // 查询销售总额
      } else if(value == 4){
        // 年份
        this.getList("rpt_transactionProfile", 4, Year ); // 查询交易概况
        this.getList("rpt_productSalesRanking", 4, Year); // 查询销售总额
      }
      let time = Year + '-' + Month + '-' + Day
      this.MonthData = time
    },
    offlienData() {
      this.$nextTick(() => {
        this.myChartLine();
      });
    },
  },
}
</script>

<style lang="less" scoped>
.main-wrap{
  background-color: #f6f6fc !important;
  margin: 15px;
  .screen-data{
    margin-bottom: 17px;
  }
  .l-r-layout{
    display: flex;
  }
  .head-title {
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 21px;
  }
  .white-inner{
    background: #FFFFFF;
    border-radius: 20px;
  }
  .enter-flex{
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    .enter-item{
      background: #FFFFFF;
      width: calc((100% - 75px) / 6);
      min-height: 90px;
      border-radius: 5px;
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 16px;
      cursor: pointer;
      i{
        position: absolute;
        right: -2px;
        top: 9px;
        font-style: normal;
        z-index: 2;
        font-size: 12px;
        -webkit-transform:rotate(45deg);
        -moz-transform:rotate(45deg);
        -o-transform:rotate(45deg);
        -ms-transform:rotate(45deg);
        //负数代表逆时针旋转
        transform:rotate(45deg);
        cursor: pointer;
      }
      .inner{
        display: flex;
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-style: normal;
        text-transform: none;
        .icon{
          width:30px;
          margin-right: 16px;
          transition: transform 0.3s ease-in-out;
        }
        .num{
          font-weight: 500;
          font-size: 24px;
          color: #000000;
          line-height: 28px;
          margin-bottom: 5px;
        }
        .font{
          font-weight: 400;
          font-size: 12px;
          color: #666666;
          line-height: 14px;
          
        }
      }
      &::after{
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-bottom: 42px solid transparent;
      }
    }
    .enter-item:hover{
      .inner .icon{
        transform: scale(1.2);
      }
    }
    .enter-item:nth-child(1){
      border-right: 3px solid #327AE6;
      i{
        color: #327AE6;
      }
      &::after{
        border-right: 42px solid rgba(50, 122, 230, 0.2);
      }
    }
    .enter-item:nth-child(2){
      border-right: 3px solid #FEBB5F;
      i{
        color: #FEBB5F;
      }
      &::after{
        border-right: 42px solid rgba(254, 187, 95, 0.2);
      }
    }
    .enter-item:nth-child(3){
      border-right: 3px solid #2FD5FF;
      i{
        color: #2FD5FF;
      }
      &::after{
        border-right: 42px solid rgba(47,213,255, 0.2);
      }
    }
    .enter-item:nth-child(4){
      border-right: 3px solid #7CD2D5;
      i{
        color: #7CD2D5;
      }
      &::after{
        border-right: 42px solid rgba(124,210,213, 0.2);
      }
    }
    .enter-item:nth-child(5){
      border-right: 3px solid #7B93F8;
      i{
        color: #7B93F8;
      }
      &::after{
        border-right: 42px solid rgba(123,147,248, 0.2);
      }
    }
    .enter-item:nth-child(6){
      border-right: 3px solid #FC7691;
      i{
        color: #FC7691;
      }
      &::after{
        border-right: 42px solid rgba(252,118,145, 0.2);
      }
    }
  }

  .trade-box{
    .trade-inner{
      width: 69%;
      min-width: 925px;
      margin-right: 15px;
      .white-inner{
        padding: 20px 25px 20px 15px;
        display: flex;
        .date-wrap{
          flex: 1;
          .date-list{
            display: flex;
            align-items: center;
            margin-bottom: 45px;
            margin-top: 20px;
            .date-item{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              text-align: left;
              font-style: normal;
              text-transform: none;
              min-width: 150px;
              .name{
                font-size: 13px;
                color: #666666;
                line-height: 15px;
              }
              .num{
                font-size: 20px;
                color: #3681F0;
                line-height: 23px;
                margin: 5px 0;
              }
              .balance{
                font-size: 12px;
                color: #999999;
                line-height: 14px;
                img{
                  width: 12px;
                  margin: 0 4px;
                }
              }
              .detail{
                color: #3681F0;
                cursor: pointer;
              }
            }
            .line{
              position: relative;
              height: 1px;
              &::before{
                content: '';
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: red;
                display: inline-block;
                position: relative;
                bottom: 11px;
              }
            }
            .line-01{
              border-top: 1px dashed #9AC1F0;
              width: calc(100% - 500px);
              &::before{
                background: #9AC1F0;
              }
            }
            .line-02{
              border-top: 1px dashed #F6C445;
              width: calc(100% - 318px);
              &::before{
                background: #F6C445;
              }
            }
            .line-03{
              width: calc(100% - 525px);
              border-top: 1px dashed #FF8A00;
              &::before{
                background: #FF8A00;
              }
            }
          }
          .date-list:last-child{
            margin-bottom: 0;
          }
        }
        .echarts-wrap{
          display: flex;
          min-width: 400px;
          width: 400px;
          position: relative;
          right: 120px;
          .funnel-div{
            text-align: center;
            width: 290px;
            .icon{
              position: relative;
            }
            .icon-top{
              width: 288px;
              top: 14px;
              z-index: 9;
            }
            .icon-bottom{
              bottom: 16px;
              width: 127px;
              z-index: 1;
            }
            .myChart-funnel{
              z-index: 6;
              position: relative;
              width: 100%;
              height: 290px;
              
            }
          }
          .conversion-rate{
            position: absolute;
            right: 0;
            bottom: 58px;
            width: 245px;
            height: 207px;
            background-image: url('~@/assets/image/dataCenter/line-bg.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            .rate-item{
              position: absolute;
              .name{
                margin-bottom: 4px;
                font-weight: 400;
                font-size: 12px;
                color: #666666;
                line-height: 14px;
              }
              .num{
                font-weight: 500;
                font-size: 14px;
                color: #333333;
                line-height: 16px;
              }
            }
            .rate-01{
              right: 14%;
              top: 17%;
            }
            .rate-02{
              right: 29%;
              top: 58%;
            }
            .rate-03{
              right: -6%;
              bottom: 0;
            }
          }
        }
      }
    }
    .system-inner{
      flex: 1;
      .head-flex{
        .time{
          font-size: 12px;
          i{
            font-style: normal;
            color: #3681F0;
          }
        }
      }
      .content-list{
        .white-inner{
          padding: 12px 20px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 13px;
          color: #333333;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-bottom: 4px;
        }
        .more-btn{
          display: block;
          margin-top: 30px;
          text-align: center;
          height: 38px;
          line-height: 38px;
          background: #EBF2FE;
          border-radius: 20px;
          font-size: 14px;
          color: #3681F0;
          cursor: pointer;
        }
      }
      .empty{
        min-height: 387px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .head-flex{
      display: flex;
      align-items: center;
      margin-bottom: 11px;
      min-height: 32px;
      .data{
        min-width: 110px;
        margin-left: 14px;
      }
      /deep/.ant-select-selection{
        border: 1px solid rgba(54,129,240,0.5);
      }
      /deep/.ant-input{
        border: 1px solid rgba(54,129,240,0.5);
      }
    }
  }

  .payment-box{
     .head-flex{
      display: flex;
      align-items: center;
      margin-bottom: 11px;
      min-height: 32px;
      .data{
        min-width: 110px;
        margin-left: 14px;
      }
      /deep/.ant-select-selection{
        border: 1px solid rgba(54,129,240,0.5);
      }
      /deep/.ant-input{
        border: 1px solid rgba(54,129,240,0.5);
      }
    }
    .left-inner{
      flex: 1;
      margin-right: 18px;
    }
    .right-inner{
      width: 40%;
      .xls-th-tr{
        display: flex;
        justify-content: space-between;
        margin: 0 8px;
        .xls-th-td{
          text-align: center;
          font-size: 14px;
          line-height: 34px;
        }
        .td-01{
          width: 14%;
        }
        .td-02{
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .td-03{
          width: 16%;
        }
        .td-04{
          width: 22%;
        }
      }
      .seamless-scroll{
        margin-top: 4px;
        height: 263px;
        overflow: hidden;
      }
      .orderline{
        border-bottom: 1px solid rgba(51,51,51, 0.2);
      }
      // .orderline:last-child{
      //   border-bottom: none;
      // }
    }
    .white-inner{
      margin-top: 15px;
      .myChart-cross{
        padding: 20px 0;
        width: 100%;
        height: 300px;
      }
    }
  } 
}
</style>
